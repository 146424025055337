import React from 'react';

import Container from '../Container';
import Row from '../Container/row';
import Col from '../Container/column';

import { BorderRadius, MainDark } from '../../utils/variables';
import colours from '../../utils/colours';

import './style.css';

import loadComponents from '../Loadable';

const Link = loadComponents('link');
const ButtonInput = loadComponents('button-input');

export default class CookiesMessage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      gdpr: true,
    };
  }

  componentDidMount() {
    this.checkStorage();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  checkStorage = () => {
    if (!this._isMounted) {
      this._isMounted = true;
      this.setState({
        gdpr: !!localStorage.getItem('gdpr'),
      });
    }
  };

  acceptCookies = () => {
    if (this._isMounted) {
      this.setState({ gdpr: true }, () => {
        localStorage.setItem('gdpr', true);
      });
    }
  };

  render() {
    const { gdpr } = this.state;
    const title = 'Privacy Policy';
    const url = '/about/privacy-policy/';
    return (
      !gdpr && (
        <div className="cookiesOuter" style={outerStyle}>
          <Container>
            <div className="cookiesInner" style={innerStyle}>
              <Row align="center" justify="space-between">
                <Col lg={{ width: 'auto' }}>
                  <strong style={headerStyle}>
                    We use cookies to improve your browsing experience
                  </strong>
                  <p style={textStyle}>
                    For more information please refer to our {` `}
                    <Link
                      to={url}
                      content={title}
                      label={`View our ${title}`}
                      style={{
                        ...textStyle,
                        borderBottom: `1px dotted rgba(255,255,255,0.6)`,
                        paddingBottom: `0.1875rem`,
                      }}
                    />
                    .
                  </p>
                </Col>
                <Col lg={{ width: 'auto' }}>
                  <ButtonInput
                    className="button"
                    value="Dismiss"
                    label="Dismiss cookies notice"
                    onClick={this.acceptCookies}
                    secondary
                  />
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )
    );
  }
}

const outerStyle = {
  position: `fixed`,
  left: 0,
  right: 0,
  zIndex: 5000,
  animation: `dropdown 0.3s forwards`,
};

const innerStyle = {
  backgroundColor: colours.hexToRGB(MainDark, 0.95),
  borderRadius: BorderRadius,
  position: `relative`,
};

const headerStyle = {
  display: `inline-block`,
  fontWeight: 800,
  marginBottom: `7px`,
  lineHeight: 1.8,
  fontFamily: `'Corinthian Bold', sans-serif`,
  textTransform: `uppercase`,
  color: `#fff`,
};

const textColour = {
  color: `#fff`,
};

const textStyle = {
  ...textColour,
};
